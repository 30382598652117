import {
  LeadBody,
  LeadBodyKey,
  LeadOwnerStatus,
  LeadProjectState,
  LeadRequestModel,
  LeadSource,
  LeadTiming,
  LeadWallInsulationType,
} from '@effy-tech/angular-common';
import {
  BinaryAnswer,
  Nullable,
  PARTNER_ITEM_NAME,
  UserKeys,
  VulcanHousingAgeValues,
  VulcanKeys,
  VulcanWorkTypeValues,
} from '@prc-lib-core';
import { AnswserKeys, ProjectStatus, TimingProject, UserStatus } from '@prc-serenity-models/keys.model';
import { AnswsersWorksState } from '@prc-serenity-store/reducers/answers-works.reducer';
import { AnswersStateNonNullable } from '@prc-serenity-store/reducers/answers.reducer';
import { UserStateNonNullable } from '@prc-serenity-store/reducers/user.reducer';
import { VulcanUtils } from '@prc-serenity-utils/vulcan/vulcan.utils';

export class SherlockUtils {
  static mapAnswsersToIntentionalityRequest(
    answers: AnswersStateNonNullable,
    answsersWorks: AnswsersWorksState,
    source: string,
    utmFunnel: string
  ): LeadRequestModel {
    return {
      body: SherlockUtils.getAnswsersData(answers, answsersWorks, utmFunnel),
      source: source as LeadSource,
    };
  }

  static mapAnswersToLeadRequest(
    user: UserStateNonNullable,
    answers: AnswersStateNonNullable,
    answsersWorks: AnswsersWorksState,
    source: string,
    gaClientId: Nullable<string>,
    utmFunnel: string
  ): LeadRequestModel {
    const body = {
      ...SherlockUtils.getAnswsersData(answers, answsersWorks, utmFunnel),
      ...this.getUserData(user, gaClientId),
    };

    return {
      body: body as unknown as LeadBody,
      source: source as LeadSource,
    };
  }

  private static getAnswsersData(
    answers: AnswersStateNonNullable,
    answsersWorks: AnswsersWorksState,
    utmFunnel: string
  ): Partial<LeadBody> {
    const worksTypes: VulcanWorkTypeValues[] = VulcanUtils.getWorksTypes(answers, answsersWorks);

    const hasSecondaryResidence = !!answers[AnswserKeys.HousingZipCode];
    const mainResidencePostalCode = hasSecondaryResidence
      ? answers[AnswserKeys.HousingZipCode]?.code_postal
      : answers[AnswserKeys.WorksAddress]?.postalCode;
    const mainResidenceCity = hasSecondaryResidence ? answers[AnswserKeys.HousingZipCode]?.nom : answers[AnswserKeys.WorksAddress]?.city;
    const partnerName = sessionStorage.getItem(PARTNER_ITEM_NAME);

    return {
      ...(!hasSecondaryResidence ? { [LeadBodyKey.Address]: answers[AnswserKeys.WorksAddress]?.streetNumberAndName } : {}),
      [LeadBodyKey.AccommodationZipCode]: answers[AnswserKeys.WorksAddress]?.postalCode,
      [LeadBodyKey.AccommodationInseeCode]: answers[AnswserKeys.WorksAddress]?.cityCode,
      [LeadBodyKey.AccommodationOlderThanTwoYears]: answers[AnswserKeys.HousingAge] !== VulcanHousingAgeValues.LessThanTwoYears,
      [LeadBodyKey.City]: mainResidenceCity,
      [LeadBodyKey.AccommodationAddress]: answers[AnswserKeys.WorksAddress]?.streetNumberAndName,
      [LeadBodyKey.AccommodationCity]: answers[AnswserKeys.WorksAddress]?.city,
      [LeadBodyKey.AccommodationLatitude]: answers[AnswserKeys.WorksAddress]?.latitude,
      [LeadBodyKey.AccommodationLongitude]: answers[AnswserKeys.WorksAddress]?.longitude,
      [LeadBodyKey.ZipCode]: mainResidencePostalCode,
      [LeadBodyKey.HousingType]: answers[AnswserKeys.HousingType],
      [LeadBodyKey.HousingAge]: answers[AnswserKeys.HousingAge],
      [LeadBodyKey.SurfaceArea]: Number(answers[AnswserKeys.HousingSurface]),
      [LeadBodyKey.ExistingEnergy]: answers[AnswserKeys.ExistingEnergy],
      [LeadBodyKey.ExistingHeatingType]: answers[AnswserKeys.ExistingHeatingType],
      [LeadBodyKey.ProjectState]: SherlockUtils.getProjectState(answers[AnswserKeys.ProjectStatus]),
      ...(SherlockUtils.getTiming(answers[AnswserKeys.TimingProject] as TimingProject)
        ? { [LeadBodyKey.Timing]: SherlockUtils.getTiming(answers[AnswserKeys.TimingProject] as TimingProject) }
        : {}),
      [LeadBodyKey.OwnerStatus]: SherlockUtils.getUserStatus(answers[AnswserKeys.UserStatus] as UserStatus),
      [LeadBodyKey.HouseOccupants]: answers[AnswserKeys.HouseholdComposition],
      [LeadBodyKey.PrecarityLevel]: VulcanUtils.getPrecarityLevel(answers[AnswserKeys.HouseholdIncome]),
      [LeadBodyKey.PrecarityAnahLevel]: VulcanUtils.getPrecarityAnahLevel(answers[AnswserKeys.HouseholdIncome]),
      [LeadBodyKey.WorksType]: worksTypes,
      [LeadBodyKey.UtmFunnel]: utmFunnel,
      ...(SherlockUtils.getSpecificWorkData(worksTypes, answsersWorks) ?? {}),
      ...(partnerName ? { [VulcanKeys.Partner]: partnerName } : {}),
    } as Partial<LeadBody>;
  }

  private static getUserData(user: UserStateNonNullable, gaClientId: string | null): Partial<LeadBody> {
    return {
      [LeadBodyKey.UserPhone1]: this.removeSpacesFromPhoneNumber(user[UserKeys.Phone]),
      [LeadBodyKey.UserCivility]: user[UserKeys.Civility],
      [LeadBodyKey.UserFirstName]: user[UserKeys.FirstName],
      [LeadBodyKey.UserLastName]: user[UserKeys.LastName],
      [LeadBodyKey.UserEmail]: user[UserKeys.Email],
      [LeadBodyKey.Uid]: user[UserKeys.Uid],
      ...(gaClientId ? { [LeadBodyKey.GaClientId]: gaClientId } : {}),
    };
  }

  private static getSpecificWorkData(worksTypes: VulcanWorkTypeValues[], answsersWorks: AnswsersWorksState): Partial<LeadBody> {
    let additionalData = {};

    if (worksTypes.includes(VulcanWorkTypeValues.AtticsInsulation)) {
      additionalData = {
        ...additionalData,
        [LeadBodyKey.AccommodationRoofType]: answsersWorks[AnswserKeys.AtticsType],
        [LeadBodyKey.AtticsInsulationWorkprojectInsulation_surface]: answsersWorks[AnswserKeys.AtticsSurface],
        'attics_insulation.workproject_insulation_type': answsersWorks[AnswserKeys.AtticsInsulationType],
      };
    }

    if (worksTypes.includes(VulcanWorkTypeValues.AirWaterHeatPump)) {
      additionalData = {
        ...additionalData,
        [LeadBodyKey.AirWaterHeatPumpWorkprojectHeatedSurface]: answsersWorks[AnswserKeys.AirWaterHeatPumpSurface],
        [LeadBodyKey.AirWaterHeatPumpWorkProjectUse]: answsersWorks[AnswserKeys.AirWaterHeatPumpSanitaryWater],
        [LeadBodyKey.AccommodationExterior]: answsersWorks[AnswserKeys.AirWaterHeatPumpExteriorSpace] === BinaryAnswer.Yes,
      };
    }

    if (worksTypes.includes(VulcanWorkTypeValues.WallInsulation)) {
      additionalData = {
        ...additionalData,
        [LeadBodyKey.WallInsulationWorkprojectInsulationSurface]: answsersWorks[AnswserKeys.WallsSurface],
        [LeadBodyKey.WallInsulationWorkprojectInsulationType]: LeadWallInsulationType.External,
        'wall_insulation.workproject_external_insulation_type': 'coating',
      };
    }

    return additionalData;
  }

  private static removeSpacesFromPhoneNumber(phoneNumber: string): string {
    return phoneNumber?.replace(/ |\u2000/g, '');
  }

  private static getProjectState(projectStatus: ProjectStatus): LeadProjectState {
    const LEAD_PROJECT_STATE_MAPPER: { [key in ProjectStatus]: LeadProjectState } = {
      [ProjectStatus.ReflectionWorks]: LeadProjectState.ReflectionWorks,
      [ProjectStatus.LookingForArtisan]: LeadProjectState.LookingForArtisan,
      [ProjectStatus.WillSignQuote]: LeadProjectState.WillSignQuote,
      [ProjectStatus.QuoteSigned]: LeadProjectState.QuoteSigned,
    };

    return LEAD_PROJECT_STATE_MAPPER[projectStatus];
  }

  private static getTiming(timing: TimingProject): LeadTiming {
    const LEAD_TIMING_MAPPER: { [key in TimingProject]: LeadTiming } = {
      [TimingProject.Asap]: LeadTiming.Asap,
      [TimingProject.Next6Months]: LeadTiming.WithinSixMonths,
      [TimingProject.DontKnow]: LeadTiming.Later,
    };

    return LEAD_TIMING_MAPPER[timing];
  }

  private static getUserStatus(userStatus: UserStatus): LeadOwnerStatus {
    const LEAD_OWNER_STATUS_MAPPER: { [key in UserStatus]: LeadOwnerStatus } = {
      [UserStatus.OwnerOccupied]: LeadOwnerStatus.ResidentOwner,
      [UserStatus.SecondHome]: LeadOwnerStatus.SecondaryResidenceOwner,
      [UserStatus.OwnerLessor]: LeadOwnerStatus.LeasingOwner,
      [UserStatus.Tenant]: LeadOwnerStatus.Tenant,
    };

    return LEAD_OWNER_STATUS_MAPPER[userStatus];
  }
}
