import { LeadRequestModel } from '@effy-tech/angular-common';
import { createAction, props } from '@ngrx/store';
import { EffyIntentionalityDataState } from '../reducers/intentionality.reducer';

export enum EffyIntentionalityAction {
  FetchIntentionality = '[EFFY Intentionality] Fetch',
  FetchIntentionalitySuccess = '[EFFY Intentionality] Fetch Success',
  FetchIntentionalityFailed = '[EFFY Intentionality] Fetch Failed',
}

export const EFFY_FETCH_INTENTIONALITY_ACTION = createAction(
  EffyIntentionalityAction.FetchIntentionality,
  props<{ request: LeadRequestModel }>()
);

export const EFFY_FETCH_INTENTIONALITY_SUCCESS_ACTION = createAction(
  EffyIntentionalityAction.FetchIntentionalitySuccess,
  props<{ results: EffyIntentionalityDataState }>()
);

export const EFFY_FETCH_INTENTIONALITY_FAILED_ACTION = createAction(EffyIntentionalityAction.FetchIntentionalityFailed);
