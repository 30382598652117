import { LeadRequestModel } from '@effy-tech/angular-common';
import { createAction, props } from '@ngrx/store';

export enum EffySherlockAction {
  CreateLead = '[EFFY Sherlock] Create Lead',
  CreateLeadSuccess = '[EFFY Sherlock] Create Lead Success',
  CreateLeadFailed = '[EFFY Sherlock] Create Lead Failed',
}

export const EFFY_CREATE_LEAD_ACTION = createAction(EffySherlockAction.CreateLead, props<{ request: LeadRequestModel }>());
export const EFFY_CREATE_LEAD_SUCCESS_ACTION = createAction(EffySherlockAction.CreateLeadSuccess, props<{ pisteId: string }>());
export const EFFY_CREATE_LEAD_FAILED_ACTION = createAction(EffySherlockAction.CreateLeadFailed);
