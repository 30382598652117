import { Injectable } from '@angular/core';
import { LeadSolutionTypeOffer, LeadWorkAndSolution, LeadsService } from '@effy-tech/angular-common';
import { Store } from '@ngrx/store';
import { CookieManagerService, CookiesGa, EFFY_CREATE_LEAD_ACTION, EFFY_FETCH_INTENTIONALITY_ACTION } from '@prc-lib-core';
import { SiteService } from '@prc-serenity-services/sites/site.service';
import { AppState } from '@prc-serenity-store/app-state.model';
import { AnswersWorksStateNonNullable } from '@prc-serenity-store/reducers/answers-works.reducer';
import { AnswersStateNonNullable } from '@prc-serenity-store/reducers/answers.reducer';
import { UserStateNonNullable } from '@prc-serenity-store/reducers/user.reducer';
import { getAnswersWorksData } from '@prc-serenity-store/selectors/answers-works.selector';
import { getAnswersData } from '@prc-serenity-store/selectors/answers.selector';
import { getUserState } from '@prc-serenity-store/selectors/user.selector';
import { SherlockUtils } from '@prc-serenity-utils/sherlock/sherlock.utils';
import { Observable, combineLatest, map, mergeMap, of, take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SherlockService {
  constructor(
    private readonly cookieManagerService: CookieManagerService,
    private readonly leadsService: LeadsService,
    private readonly siteService: SiteService,
    private readonly store: Store<AppState>
  ) {}

  dispatchFetchIntentionality(): void {
    combineLatest([this.store.select(getAnswersData), this.store.select(getAnswersWorksData)])
      .pipe(
        take(1),
        map(([answers, answersWorks]) =>
          SherlockUtils.mapAnswsersToIntentionalityRequest(
            answers as AnswersStateNonNullable,
            answersWorks as AnswersWorksStateNonNullable,
            this.siteService.origin,
            this.siteService.utmFunnel
          )
        )
      )
      .subscribe(intentionalityBody => this.store.dispatch(EFFY_FETCH_INTENTIONALITY_ACTION({ request: intentionalityBody })));
  }

  dispatchCreateLead(): void {
    combineLatest([this.store.select(getUserState), this.store.select(getAnswersData), this.store.select(getAnswersWorksData)])
      .pipe(
        take(1),
        map(([user, answers, answersWorks]) =>
          SherlockUtils.mapAnswersToLeadRequest(
            user as UserStateNonNullable,
            answers as AnswersStateNonNullable,
            answersWorks as AnswersWorksStateNonNullable,
            this.siteService.origin,
            this.getGaClientId(),
            this.siteService.utmFunnel
          )
        )
      )
      .subscribe(leadBody => this.store.dispatch(EFFY_CREATE_LEAD_ACTION({ request: leadBody })));
  }

  getIsEligibleForSerenity$(): Observable<boolean> {
    // When partner is also accepting
    if (this.siteService.isLibertyAllowed) {
      return of(true);
    }

    return combineLatest([this.store.select(getUserState), this.store.select(getAnswersData), this.store.select(getAnswersWorksData)]).pipe(
      take(1),
      map(([user, answers, answersWorks]) =>
        SherlockUtils.mapAnswersToLeadRequest(
          user as UserStateNonNullable,
          answers as AnswersStateNonNullable,
          answersWorks as AnswersWorksStateNonNullable,
          this.siteService.origin,
          this.getGaClientId(),
          this.siteService.utmFunnel
        )
      ),
      mergeMap(leadData => this.leadsService.getWorksAndSolutionsFromLeadData(leadData)),
      map(worksAndSolutions => this.hasAtLeastOneSerenityOffer(worksAndSolutions))
    );
  }

  private hasAtLeastOneSerenityOffer(worksAndSolutions: LeadWorkAndSolution[]): boolean {
    // @TODO : Possibly add work type check when/if multi work
    const SERENITY_OFFERS_TYPES = [LeadSolutionTypeOffer.Serenity, LeadSolutionTypeOffer.SolarOffer] as string[];
    return worksAndSolutions.some(workAndSolution => SERENITY_OFFERS_TYPES.includes(workAndSolution.type_offre));
  }

  private getGaClientId(): string | null {
    return this.cookieManagerService.getCookie(CookiesGa.clientId);
  }
}
